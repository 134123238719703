import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useFileList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refFilesTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'File_No',
      label: 'File No.',
      sortable: false,
    },
    {
      key: 'fileType',
      label: 'Product Description',
      sortable: false,
    },
    {
      key: 'purchaserName',
      label: 'customer',
      sortable: false,
    },
    {
      key: 'File_Status',
      label: 'file status',
      sortable: false,
    },
    {
      key: 'paymentStatus',
      label: 'payment status',
      sortable: false,
    },
    {
      key: 'Booking_Date',
      label: 'booking date',
      sortable: false,
    },
    {
      key: 'Confirm_Date',
      label: 'confirm date',
      sortable: false,
    },
    {
      key: 'Created_On',
      label: 'Created By',
      sortable: true,
    },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalFiles = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const fileNo = ref(route.query.fileNo ?? '')
  const nric = ref(route.query.nric ?? '')
  const lotNo = ref(route.query.lotNo ?? '')
  const sortBy = ref(route.query.sortBy ?? 'File_No')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const fileStatusFilter = ref([])
  if (Array.isArray(route.query.fileStatus)) {
    fileStatusFilter.value = route.query.fileStatus
  } else if (route.query.fileStatus) {
    fileStatusFilter.value = [route.query.fileStatus]
  }
  const paymentStatusFilter = ref([])
  if (Array.isArray(route.query.paymentStatus)) {
    paymentStatusFilter.value = route.query.paymentStatus
  } else if (route.query.paymentStatus) {
    paymentStatusFilter.value = [route.query.paymentStatus]
  }
  const productFilter = ref([])
  if (Array.isArray(route.query.product)) {
    productFilter.value = route.query.product
  } else if (route.query.product) {
    productFilter.value = [route.query.product]
  }
  const customerTypeFilter = ref([])
  if (Array.isArray(route.query.customerType)) {
    customerTypeFilter.value = route.query.customerType
  } else if (route.query.customerType) {
    customerTypeFilter.value = [route.query.customerType]
  }
  const filesData = ref([])
  const bookingDateFilter = ref(route.query.bookingDate || '')
  const confirmDateFilter = ref(route.query.confirmDate || '')
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refFilesTable.value ? refFilesTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFiles.value,
    }
  })

  const refetchData = () => {
    refFilesTable.value.refresh()
  }

  const fetchFiles = () => {
    store
      .dispatch('app-all-files/fetchFiles', {
        search: searchQuery.value,
        fileNo: fileNo.value,
        nric: nric.value,
        lotNo: lotNo.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        fileStatus: fileStatusFilter.value,
        paymentStatus: paymentStatusFilter.value,
        product: productFilter.value,
        customerType: customerTypeFilter.value,
        bookingDate: bookingDateFilter.value,
        confirmDate: confirmDateFilter.value,
      },
      { root: true })
      .then(response => {
        const { files, totalFiles: totalFilesCount } = response.data
        filesData.value = files
        // callback(files)
        totalFiles.value = totalFilesCount
        // refetchData()
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              fileNo: fileNo.value,
              nric: nric.value,
              lotNo: lotNo.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              fileStatus: fileStatusFilter.value,
              paymentStatus: paymentStatusFilter.value,
              product: productFilter.value,
              customerType: customerTypeFilter.value,
              bookingDate: bookingDateFilter.value,
              confirmDate: confirmDateFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  let timer = null
  watch([fileStatusFilter, paymentStatusFilter, productFilter, customerTypeFilter, bookingDateFilter, confirmDateFilter], () => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      currentPage.value = 1
      fetchFiles()
    }, 1000)
  }, { deep: true })

  watch([searchQuery], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchFiles()
      }
    }, 1000)
  }, { deep: true })

  watch([fileNo], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchFiles()
      }
    }, 1000)
  }, { deep: true })

  watch([nric], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchFiles()
      }
    }, 1000)
  }, { deep: true })

  watch([lotNo], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchFiles()
      }
    }, 1000)
  }, { deep: true })

  watch([currentPage], () => {
    fetchFiles()
  }, { deep: true })

  fetchFiles()

  return {
    fetchFiles,
    tableColumns,
    perPage,
    currentPage,
    totalFiles,
    dataMeta,
    perPageOptions,
    searchQuery,
    fileNo,
    nric,
    lotNo,
    sortBy,
    isSortDirDesc,
    refFilesTable,
    refetchData,
    filesData,

    // Extra Filters
    fileStatusFilter,
    paymentStatusFilter,
    productFilter,
    customerTypeFilter,
    bookingDateFilter,
    confirmDateFilter,
  }
}
